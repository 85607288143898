<template>
    <div class="root_inv">
        <!-- {{ arr2 }} -->
        <h1>Products</h1>
        <div class="box_inv">
            <b-table responsive="sm" id="my-table" :items="arr" :per-page="perPage" :current-page="currentPage" />
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                aria-controls="my-table"></b-pagination>
        </div>
        <h1>Variants</h1>
        <div class="box_inv">
            <b-table responsive="sm" id="my-table2" :items="arr2" :per-page="perPage" :current-page="currentPage2" />
            <!-- <b-table responsive="sm" id="my-table2" :items="arr2" :per-page="perPage" :current-page="currentPage2" /> -->
            <b-pagination v-model="currentPage2" :total-rows="rows2" :per-page="perPage"
                aria-controls="my-table2"></b-pagination>
        </div>

        <!-- <b-table responsive="sm" :items="allProducts" /> -->
    </div>
</template>
<script>
import { BModal, VBModal, BAlert, BCardText } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";
import {
    BPagination,
    BRow,
    BCol,
    BLink,
    BButton,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BCardTitle,
    BDropdown, BDropdownItem,
    BTable,
} from "bootstrap-vue";
import { isPlainObject } from "@vue/shared";

export default {
    components: {
        BDropdown, BDropdownItem,
        BTable,
        BCardCode,
        BButton,
        BModal,
        BAlert,
        BRow,
        BImg,
        BCol,
        BLink,
        BForm,
        BCardText,
        BCardTitle,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BInputGroup,
        BInputGroupAppend,
        ValidationProvider,
        ValidationObserver,
        BPagination,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            perPage: 5,
            currentPage: 1,
            currentPage2: 1,
            arr: [],
            arr2: [],
        };
    },
    methods: {
        ...mapActions({
            fetchAllProducts: "storeProducts/fetchAllProducts",
            fetchAllVariants: "storeProducts/fetchAllVariants",
            getVendorName: "storeProducts/getVendorName"
        }),
    },
    computed: mapState({
        allProducts(state) {
            return state.storeProducts.allProducts;
        },
        allVariants(state) {
            return state.storeProducts.allVariants;
        },
        rows() {
            return this.arr.length;
        },
        rows2() {
            return this.arr2.length;
        },
    }),

    async mounted() {
        await this.fetchAllProducts();
        await this.fetchAllVariants();
        let mp = {};
        this.arr = [];

        Promise.all(this.allProducts.map(async (e) => {
            let obj = {};
            obj.SKU = e.SKU;
            obj.name = e.name;
            obj.category = e.productCategory[0].name;
            obj.subCategory = e.subCategory;
            obj.vendor = await this.getVendorName({id: obj.vendorId})
            // if (e.vendor.length) {
            //     obj.vendor = obj.vendor[0].name
            // }
            const tempObj = {
                name: e.name,
                SKU: e.SKU
            }
            mp[e.id] = tempObj;
            this.arr.push(obj);
        })).then(() => {
            this.arr2 = this.allVariants.map(e => {
                // console.log(e.SKU);
                let obj = {};
                obj.SKU = e.SKU;
                if(!mp[e.productId])return;
                obj.productSKU = mp[e.productId].SKU;
                obj.productName = mp[e.productId].name;
                obj.name = e.name;
                obj.priceOffer = e.priceOffer ? e.priceOffer : null;
                obj.priceOriginal = e.priceOriginal;
                obj.inventoryQuantity = e.inventoryQuantity;
                obj.status = e.status;
                return obj;
            })
        });
    },
};
</script>
<style>
.box_inv {
    max-width: 86%;
}
</style>